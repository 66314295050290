import React from "react";

import { Link } from "react-router-dom";

import { Grid, Typography } from "@material-ui/core";
import { HEADER_TEXT, POLYGON_CONSTANT_TEXT, POLYGON_VIEW_TYPE } from "../Utils/PlolygonServicesConstants";
import { PlolygonServicesInitialStateInteface } from "../Utils/PlolygonServicesInterface";
import { APP_ROUTES, USER_TYPES } from "../../../config/constants";

import PrimaryButton from "../../common/PrimaryButton/PrimaryButton";

interface PlolygonServicesHeaderInterface {
  classes: any;
  appStateObj: PlolygonServicesInitialStateInteface;
  isExporting: any;
  handleExportCSVClick: any;
  switchPolygonView: any;
  userInfo: any;
}

const PlolygonServicesHeader = (props: PlolygonServicesHeaderInterface) => {
  const { classes, appStateObj, isExporting, handleExportCSVClick, switchPolygonView, userInfo } = props;

  const isNewConfigurationButtonVisible = () => {
    return userInfo?.userProfile?.role && (userInfo?.userProfile?.role === USER_TYPES.MAF_ADMIN || userInfo?.userProfile?.role === USER_TYPES.SUPER_ADMIN);
  };

  return (
    <Grid container className={classes.headerRoot}>
      <Grid item xs={4} className={classes.header}>
        <Typography className={classes.heading}>{`${HEADER_TEXT}`}</Typography>
      </Grid>
      <Grid item xs={8} className={classes.clearFilterDiv}>
        <PrimaryButton
          buttonLabel={appStateObj.polygonView === POLYGON_VIEW_TYPE.LISTING ? "Map View" : "List View"}
          buttonVariant="outlined"
          disabled={isExporting}
          onClick={switchPolygonView}
          className={classes.fullWidthButton}
        />
        {appStateObj?.polygonServiceListData?.length > 0 && (
          <PrimaryButton
            buttonLabel={isExporting ? "Exporting..." : "Export Mapping"}
            buttonVariant="outlined"
            disabled={isExporting}
            onClick={handleExportCSVClick}
            className={classes.fullWidthButton}
          />
        )}
        {isNewConfigurationButtonVisible() && (
          <Link to={APP_ROUTES.POLYGON_SERVICES_CONFIGURATION} style={{ textDecoration: "none" }}>
            <PrimaryButton
              buttonLabel={`${POLYGON_CONSTANT_TEXT.CREATE_NEW_CONFIGURATION_BUTTON}`}
              buttonVariant="contained"
              disabled={false}
              onClick={switchPolygonView}
              className={classes.fullWidthConfigurationButton}
            />
          </Link>
        )}
      </Grid>
    </Grid>
  );
};

export default React.memo(PlolygonServicesHeader);
