import React from "react";
import moment from "moment";

import { Accordion, AccordionDetails, AccordionSummary, Card, CardContent, Grid, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import ModalContainer from "../../../base/ModalContainer";
import PrimaryButton from "../../common/PrimaryButton/PrimaryButton";

import { userStyles } from "../Styles/RenderPolygonDetailsPopUpStyles";
import { createCamelCase } from "../../../utils/helperFunctions";
import { POLYGON_CONSTANT_TEXT } from "../Utils/PlolygonServicesConstants";
import { isArrayValid, isUndefined } from "../../../utils/DataUtils";

interface RenderPolygonDetailsPopUpInterface {
  open: boolean;
  handleClose: any;
  polygonDetailsPopUpData: any;
}

const RenderPolygonDetailsPopUp = (props: RenderPolygonDetailsPopUpInterface) => {
  const { open, handleClose, polygonDetailsPopUpData } = props;

  const classes = userStyles();

  const renderNoDataAvailable = () => {
    return (
      <Card className={classes.nonDataPresentCard}>
        <CardContent className={classes.nonDataPresentCardContent}>
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <Typography className={classes.nonDataPresentCardSpanStyle}>{`No data available`}</Typography>
          </Grid>
        </CardContent>
      </Card>
    );
  };

  const generatePosZoneAreaText = (mappingObj: any) => {
    let areaText: string = "";
    areaText = `${mappingObj?.posNo ? mappingObj?.posNo : ""} ${mappingObj?.posName ? " - " + mappingObj?.posName : ""} ${mappingObj?.zoneName ? " - " + mappingObj?.zoneName : ""}`;
    return <Typography className={classes.configurationValueServiceTextStyle}>{`${areaText}`}</Typography>;
  };

  const generateServiceAccordianDetails = (serviceType: string) => {
    const serviceObjIndex = polygonDetailsPopUpData.serviceTypeMappings.findIndex((item: any) => item?.serviceType?.toUpperCase() === serviceType?.toUpperCase());
    const serviceObj = serviceObjIndex !== -1 ? polygonDetailsPopUpData.serviceTypeMappings[serviceObjIndex] : {};
    return (
      <Accordion key={`${serviceObjIndex || Math.random()}-${serviceObj?.serviceType}-serviceObj-service-type`} className={classes.accordionStyle} defaultExpanded={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionSummaryStyle}>
          <Grid container justifyContent="space-between" spacing={0}>
            <Grid item xs={12} className={classes.marginAuto}>
              <Typography className={classes.accordionTitle}>{createCamelCase(serviceType)}</Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetailsStyle}>
          <Grid container spacing={1}>
            <Grid item xs={1} className={classes.marginAuto}>
              <Typography className={classes.configurationHeaderTextStyle}>{`${POLYGON_CONSTANT_TEXT.START_TIME}`}</Typography>
            </Grid>
            <Grid item xs={1} className={classes.marginAuto}>
              <Typography className={classes.configurationHeaderTextStyle}>{`${POLYGON_CONSTANT_TEXT.END_TIME}`}</Typography>
            </Grid>
            <Grid item className={classes.marginAuto}>
              <Typography className={classes.configurationHeaderTextStyle}>{`${POLYGON_CONSTANT_TEXT.POS_ZONE}`}</Typography>
            </Grid>
          </Grid>
          {!isUndefined(serviceObj.storeDayWiseMappings) && isArrayValid(serviceObj.storeDayWiseMappings)
            ? serviceObj.storeDayWiseMappings.map((storeDayWiseObj: any, storeDayWiseObjIndex: any) =>
                !isUndefined(storeDayWiseObj) && !isUndefined(storeDayWiseObj.mappings) && isArrayValid(storeDayWiseObj.mappings)
                  ? storeDayWiseObj.mappings.map((mappingObj: any, mappingObjIndex: number) => (
                      <Grid container spacing={1} key={`mappingObj-${mappingObjIndex}-storeDayWiseObj-${storeDayWiseObjIndex}`}>
                        <Grid item xs={1} className={classes.marginAuto}>
                          <Typography className={classes.configurationValueTextStyle}>{`${
                            moment(mappingObj.startTime, POLYGON_CONSTANT_TEXT.BACK_END_TIME_FORMAT).isValid() ? moment(mappingObj.startTime, POLYGON_CONSTANT_TEXT.BACK_END_TIME_FORMAT).format(POLYGON_CONSTANT_TEXT.VIEW_TIME_FORMAT) : "-"
                          }`}</Typography>
                        </Grid>
                        <Grid item xs={1} className={classes.marginAuto}>
                          <Typography className={classes.configurationValueTextStyle}>{`${
                            moment(mappingObj.endTime, POLYGON_CONSTANT_TEXT.BACK_END_TIME_FORMAT).isValid() ? moment(mappingObj.endTime, POLYGON_CONSTANT_TEXT.BACK_END_TIME_FORMAT).format(POLYGON_CONSTANT_TEXT.VIEW_TIME_FORMAT) : "-"
                          }`}</Typography>
                        </Grid>
                        <Grid item className={classes.marginAuto}>
                          {mappingObj.isServiceable !== undefined && mappingObj.isServiceable ? (
                            generatePosZoneAreaText(mappingObj)
                          ) : (
                            <Typography className={classes.configurationValueNonServiceTextStyle}>{`${POLYGON_CONSTANT_TEXT.NON_SERVICEABLE_AREA}`}</Typography>
                          )}
                        </Grid>
                      </Grid>
                    ))
                  : renderNoDataAvailable()
              )
            : renderNoDataAvailable()}
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <ModalContainer open={open} onClose={handleClose}>
      <Card className={classes.cardContainer}>
        <CardContent className={classes.cardContentStyle}>
          <Grid container spacing={0} className={classes.gridContainerMain}>
            <Grid item xs={6} className={classes.gridItemTitleDivStyle}>
              <Typography className={classes.headerSpanStyle}>{`Polygon ID ${polygonDetailsPopUpData.polygonId !== undefined && polygonDetailsPopUpData.polygonId !== "" ? ` : ${polygonDetailsPopUpData.polygonId}` : ""}`}</Typography>
            </Grid>
          </Grid>
          {!isUndefined(polygonDetailsPopUpData.serviceTypeMappings) !== undefined && isArrayValid(polygonDetailsPopUpData.serviceTypeMappings) ? (
            <Grid className={classes.configDetailsDiv}>
              {generateServiceAccordianDetails(POLYGON_CONSTANT_TEXT.STANDARD)}
              {generateServiceAccordianDetails(POLYGON_CONSTANT_TEXT.EXPRESS)}
              {generateServiceAccordianDetails(POLYGON_CONSTANT_TEXT.DEFAULT)}
            </Grid>
          ) : (
            renderNoDataAvailable()
          )}

          {/* Footer */}
          <Grid container className={classes.gridContainerFooter}>
            <PrimaryButton className={classes.cancelButtonStyle} buttonLabel="Close" onClick={() => handleClose()} />
          </Grid>
        </CardContent>
      </Card>
    </ModalContainer>
  );
};

export default React.memo(RenderPolygonDetailsPopUp);
