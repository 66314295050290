import { createStyles, makeStyles } from "@material-ui/core";
import { COLORS } from "../../../config/theme/theme";

const BUTTON_WIDTH = "212px";

export const userStyles = makeStyles(() =>
  createStyles({
    cardContainer: {
      margin: "8vh auto 0px auto",
      padding: 0,
      width: "calc(85vw)",
      maxHeight: "calc(85vh)",
      overflow: "auto",
      border: "none",
      borderRadius: "12px",
      boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.15)",
      "&:focus-visible": {
        border: "none",
        outline: "none",
      },
    },
    cardContentStyle: {
      margin: 0,
      padding: 0,
      "&:last-child": {
        margin: 0,
        padding: 0,
      },
    },
    gridContainerMain: {
      margin: 0,
      padding: "24px 8px 0px 24px",
    },
    gridItemTitleDivStyle: {
      margin: 0,
      padding: 0,
    },
    gridItemServiceableDivStyle: {
      margin: 0,
      padding: 0,
      display: "flex",
      justifyContent: "flex-end",
    },
    headerSpanStyle: {
      fontWeight: 600,
      fontSize: "16px",
      color: COLORS.MINE_SHAFT_LIGHT,
    },
    //
    configDetailsDiv: {
      padding: "0px 24px 0px 24px",
      margin: "24px 0px 0px 0px",
    },
    accordionStyle: {
      background: COLORS.WHITE,
      border: "1px solid #C3C3C3",
      borderRadius: "4px",
      marginTop: "12px",
      "&:last-child": {
        padding: 0,
        margin: 0,
        marginTop: "12px",
      },
    },
    accordionSummaryStyle: {
      padding: "12px 24px 12px 12px",
    },
    accordionTitle: {
      fontSize: "18px",
      fontWeight: 600,
    },
    accordionDetailsStyle: {
      borderTop: "1px solid #C3C3C3",
      display: "block",
      padding: "12px",
    },
    marginAuto: {
      margin: "auto 0px",
    },
    configurationHeaderTextStyle: {
      fontWeight: 400,
      fontSize: "14px",
      color: COLORS.MINE_SHAFT_LIGHT,
      margin: "0px 0px 8px 0px",
    },
    configurationValueTextStyle: {
      fontWeight: 500,
      fontSize: "14px",
      color: COLORS.MINE_SHAFT_LIGHT,
      margin: "0px 0px 4px 0px",
    },
    configurationValueServiceTextStyle: {
      fontWeight: 600,
      fontSize: "14px",
      color: COLORS.DOS_BLUE_DARK,
      margin: "0px 0px 4px 0px",
    },
    configurationValueNonServiceTextStyle: {
      fontWeight: 600,
      fontSize: "14px",
      color: COLORS.FROLY,
      margin: "0px 0px 4px 0px",
    },
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    nonDataPresentCard: {
      margin: 0,
      padding: "24px",
      boxShadow: "none",
    },
    nonDataPresentCardContent: {
      padding: 0,
      margin: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "calc(36vh - 48px)",
      fontSize: "14px",
      fontWeight: 400,
      color: COLORS.MINE_SHAFT,
      border: "1px solid",
      borderColor: COLORS.ATHENS_GRAY,
      borderRadius: "0px 0px 12px 12px",
      background: COLORS.ATHENS_GRAY,
      "&:last-child": {
        padding: 0,
      },
    },
    nonDataPresentCardSpanStyle: {
      color: COLORS.FROLY,
      fontWeight: 500,
      fontSize: "14px",
    },
    // Footer
    gridContainerFooter: {
      margin: 0,
      padding: "24px 24px 16px 24px",
      display: "flex",
      justifyContent: "flex-end",
    },
    cancelButtonStyle: {
      width: BUTTON_WIDTH,
      background: COLORS.WHITE,
      border: "1px solid",
      borderColor: COLORS.DOS_BLUE_DARK,
      borderRadius: "12px",
      color: COLORS.DOS_BLUE_DARK,
      textDecoration: "none",
      "&:hover": {
        background: COLORS.WHITE,
        border: "1px solid",
        borderColor: COLORS.DOS_BLUE_DARK,
      },
      "&.MuiButton-label": {
        background: COLORS.WHITE,
        border: "1px solid",
        borderColor: COLORS.DOS_BLUE_DARK,
        "&:hover": {
          background: COLORS.WHITE,
          border: "1px solid",
          borderColor: COLORS.DOS_BLUE_DARK,
        },
      },
    },
  })
);
