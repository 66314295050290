import React, { useState } from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloseIcon from "@material-ui/icons/Close";

import { POLYGON_CONSTANT_TEXT } from "../Utils/PlolygonServicesConstants";
import { userStyles } from "../../common/CustomPosZoneMapping/CustomPosZoneMappingStyle";
import { isArrayValid } from "../../../utils/DataUtils";

import TextBox from "../../common/TextBox";
import searchIcon from "../../assets/svg/SearchIcon.svg";
import EyeIcon from "../../assets/svg/EyeIconDetails.svg";

interface RenderPolygonIdsMappingInterface {
  classes: any;
  allPolygonListIdsRecord: string[];
  titleCount: string | number;
  totalPolygonIds: number;
  mappedPolygonIdsRecord: string[];
  polygonIdsSearchTextValue: string;
  mappedPolygonIdsSearchTextValue: string;
  //
  getPolygonDetails: any;
  headerPolygonIdsRowCheckboxOnchange: any;
  tableRowCellCheckboxOnchange: any;
  handlePolygonIdSearchTextChange: any;
  handleMappedPolygonIdSearchTextChange: any;
  deleteSelectedMappedPolygonId: any;
  //
  getPolygonIdsRecordList: any;
  getMappedPolygonIdsRecordList: any;
}

const RenderPolygonIdsMapping = (props: RenderPolygonIdsMappingInterface) => {
  const {
    classes,
    allPolygonListIdsRecord,
    titleCount,
    totalPolygonIds,
    mappedPolygonIdsRecord,
    polygonIdsSearchTextValue,
    mappedPolygonIdsSearchTextValue,
    getPolygonDetails,
    headerPolygonIdsRowCheckboxOnchange,
    tableRowCellCheckboxOnchange,
    handlePolygonIdSearchTextChange,
    handleMappedPolygonIdSearchTextChange,
    deleteSelectedMappedPolygonId,
    //
    getPolygonIdsRecordList,
    getMappedPolygonIdsRecordList,
  } = props;
  const tableClasses: any = userStyles();
  const polygonIdsRecordList = getPolygonIdsRecordList();

  const renderPolygonIdsRecordList = (polygonIdsRecordList: string[]) => {
    return (
      <Table padding="none" size="small" stickyHeader={true}>
        <TableHead>
          <TableRow>
            <TableCell align="left" padding="none" size="small" sortDirection={false} width="10%" className={tableClasses.tableDataRowHeaderCell}>
              <FormControlLabel
                key={`headerColumns-checkbox`}
                control={
                  <Checkbox
                    name={"tableHeaderColumns"}
                    checked={Number(totalPolygonIds) > 0 && mappedPolygonIdsRecord?.length === Number(totalPolygonIds) ? true : false}
                    onChange={headerPolygonIdsRowCheckboxOnchange}
                  />
                }
                label={""}
              />
            </TableCell>
            <TableCell align="left" padding="none" size="small" className={tableClasses.tableDataRowHeaderCell}>
              <Typography className={tableClasses.tableDataHeaderCell}>{`${POLYGON_CONSTANT_TEXT.POLYGON_ID}`}</Typography>
            </TableCell>
            <TableCell align="center" padding="none" size="small" className={tableClasses.tableDataRowHeaderCell}>
              <Typography className={tableClasses.tableDataHeaderCell}>{`${POLYGON_CONSTANT_TEXT.VIEW}`}</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isArrayValid(polygonIdsRecordList) && polygonIdsRecordList?.length > 0 ? (
            polygonIdsRecordList
              .filter((polygonId: string, polygonIdIndex: number) => polygonIdIndex < 100)
              .map((polygonId: string, polygonIdIndex: number) => (
                <>
                  <TableRow key={`all-Polygon-List-Ids-Record-${polygonIdIndex}`} className={tableClasses.tablePoygonDataRow}>
                    <TableCell align="left" padding="none" size="small" width="10%" className={tableClasses.tableDataRowCell}>
                      <FormControlLabel
                        control={<Checkbox name={"posZoneServiceDataList"} checked={mappedPolygonIdsRecord.indexOf(polygonId) !== -1} onChange={(e) => tableRowCellCheckboxOnchange(e, polygonId)} />}
                        label={""}
                      />
                    </TableCell>
                    <TableCell align="left" padding="none" size="small" className={tableClasses.tableDataRowCell}>
                      {polygonId}
                    </TableCell>
                    <TableCell align="center" padding="none" size="small" className={tableClasses.tableDataRowCell}>
                      <img src={EyeIcon} alt="search" onClick={() => getPolygonDetails(polygonId)} />
                    </TableCell>
                  </TableRow>
                </>
              ))
          ) : (
            <TableRow>
              <TableCell colSpan={5} padding="none" align="center" className={tableClasses.noDataCellStyle}>
                No data available
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    );
  };

  const renderMappedPolygonIdsRecordList = (mappedPolygonIdsRecordList: string[]) => {
    return (
      <Table padding="none" size="small" stickyHeader={true}>
        <TableHead>
          <TableRow>
            <TableCell align="left" padding="none" size="small" className={tableClasses.tableDataRowHeaderCell}>
              <Typography className={tableClasses.tableDataHeaderCell}>{`${POLYGON_CONSTANT_TEXT.POLYGON_ID}`}</Typography>
            </TableCell>
            <TableCell align="center" padding="none" size="small" className={tableClasses.tableDataRowHeaderCell}>
              <Typography className={tableClasses.tableDataHeaderCell}>{`${POLYGON_CONSTANT_TEXT.ACTION}`}</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isArrayValid(mappedPolygonIdsRecordList) && mappedPolygonIdsRecordList?.length > 0 ? (
            mappedPolygonIdsRecordList
              .filter((polygonId: string, polygonIdIndex: number) => polygonIdIndex < 100)
              .map((polygonId: string, polygonIdIndex: number) => (
                <>
                  <TableRow key={`all-Polygon-List-Ids-Record-${polygonIdIndex}`} className={tableClasses.tablePoygonDataRow}>
                    <TableCell align="left" padding="none" size="small" className={tableClasses.tableDataRowCell}>
                      {polygonId}
                    </TableCell>
                    <TableCell align="center" padding="none" size="small" className={tableClasses.tableDataRowCell}>
                      <CloseIcon
                        fontSize="small"
                        style={{
                          width: "20px",
                          height: "20px",
                          margin: 0,
                          padding: "2px",
                          color: "#000000",
                          background: "#FFFFFF",
                          border: "1px solid #000000",
                          borderRadius: "50%",
                          cursor: "pointer",
                        }}
                        onClick={(e: any) => deleteSelectedMappedPolygonId(e, polygonId)}
                      />
                    </TableCell>
                  </TableRow>
                </>
              ))
          ) : (
            <TableRow>
              <TableCell colSpan={5} padding="none" align="center" className={tableClasses.noDataCellStyle}>
                No data available
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    );
  };

  return (
    <Accordion className={classes.accordionStyle}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionSummaryStyle}>
        <Grid container justifyContent="space-between" spacing={0}>
          <Grid item xs={12} className={classes.marginAuto}>
            <Typography className={classes.accordionTitle}>{POLYGON_CONSTANT_TEXT.POLYGON_ID_MAPPING}</Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetailsStyle}>
        <Grid className={classes.innerAccordionMaindiv}>
          <Grid className={tableClasses.rootDiv}>
            <Grid className={tableClasses.containerDiv}>
              <Typography className={tableClasses.containerTitle}>{`${POLYGON_CONSTANT_TEXT.POLYGON_IDS} (${polygonIdsRecordList?.length || "0"})`}</Typography>
              <TextBox
                textBoxId="searchTextBox"
                placeholderText="Quick search by Polygon ID"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton edge="end" size="small">
                      <img src={searchIcon} alt="search" />
                    </IconButton>
                  </InputAdornment>
                }
                value={polygonIdsSearchTextValue}
                name={"polygonIdsSearchTextValue"}
                handleChange={handlePolygonIdSearchTextChange}
                className={tableClasses.searchBoxGridDiv}
              ></TextBox>

              <Grid item xs={12} className={tableClasses.tableMainGridDiv}>
                <>{renderPolygonIdsRecordList(polygonIdsRecordList)}</>
              </Grid>
            </Grid>
          </Grid>
          <Grid className={tableClasses.rootDiv}>
            <Grid className={tableClasses.containerDiv} style={{ float: "right" }}>
              <Typography className={tableClasses.containerTitle}> {`${POLYGON_CONSTANT_TEXT.MAPPED_POLYGON_IDS} (${mappedPolygonIdsRecord?.length || "0"})`}</Typography>
              <TextBox
                textBoxId="searchTextBox"
                placeholderText="Quick search by Polygon ID"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton edge="end" size="small">
                      <img src={searchIcon} alt="search" />
                    </IconButton>
                  </InputAdornment>
                }
                value={mappedPolygonIdsSearchTextValue}
                name={"mappedPolygonIdsSearchTextValue"}
                handleChange={handleMappedPolygonIdSearchTextChange}
                className={tableClasses.searchBoxGridDiv}
              ></TextBox>
              <Grid item xs={12} className={tableClasses.tableMainGridDiv}>
                <>{renderMappedPolygonIdsRecordList(getMappedPolygonIdsRecordList())}</>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default React.memo(RenderPolygonIdsMapping);
