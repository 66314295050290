import React from "react";
import { Link } from "react-router-dom";

import { Grid, Typography, Tooltip } from "@material-ui/core";

import settingsIcon from "../../assets/svg/Settings.svg";

import { APP_ROUTES, SLOT_STATUSES } from "../../../config/constants";
import { convertSecondsIntoMinutes } from "../../../utils/helperFunctions";
import { canChangeRuleEngineConfig } from "../RuleEngineAccessControl";
import { capitalizeFirstLetter, isUndefined } from "../../../utils/DataUtils";
import { PROMISE_TYPE } from "../RuleEngineUtils";
import { HEADER_NAME_CONSTANT, OrderVelocityRuleValue, PARAM_INTERFACE, PromiseConfigMaxTime, RULE_ENGINE_LISTING_TEXT_CONSTANT, ScalableItemType } from "./CurrentConfigConstant";

export const convertWeightInGramsToKiloGrams = (weightInGrams: number) => (weightInGrams ? Math.floor(Number(weightInGrams) / 1000) : weightInGrams);
export const isStatusActivated = (params: PARAM_INTERFACE) => params?.row?.status && params?.row?.status === SLOT_STATUSES.ACTIVATED;

export const isDynamicPromiseFlag = (params: PARAM_INTERFACE) => (params?.row?.dynamicPromise ? RULE_ENGINE_LISTING_TEXT_CONSTANT.DYNAMIC : RULE_ENGINE_LISTING_TEXT_CONSTANT.STATIC);
export const isPromiseStaticOrDynamic = (param: PARAM_INTERFACE) => param?.row?.type === PROMISE_TYPE.STATIC || param?.row?.type === PROMISE_TYPE.DYNAMIC;
export const isPromiseDynamicOrAutomated = (param: PARAM_INTERFACE) => param?.row?.type === PROMISE_TYPE.AUTOMATED || param?.row?.type === PROMISE_TYPE.DYNAMIC;
export const isPromiseDynamic = (param: PARAM_INTERFACE) => param?.row?.type === PROMISE_TYPE.DYNAMIC;
export const isPromiseAutomated = (param: PARAM_INTERFACE) => param?.row?.type === PROMISE_TYPE.AUTOMATED;
export const isVehicleTypeRuleActive = (params: PARAM_INTERFACE) => params?.row?.rulesData?.vehicleTypeRule?.active;
export const isCountRuleActive = (params: PARAM_INTERFACE) => params?.row?.rulesData?.countRule?.active;
export const isWeightRuleActive = (params: PARAM_INTERFACE) => params?.row?.rulesData?.weightRule?.active;
export const isOrderVelocityRuleActive = (params: PARAM_INTERFACE) => params?.row?.rulesData?.orderVelocityRule?.active;
export const isMaximumPromiseRuleActive = (params: PARAM_INTERFACE) => params?.row?.promiseConfig?.maxTime?.length > 0;
export const isScalableTypeRuleActive = (params: PARAM_INTERFACE) => params?.row?.rulesData?.scalableTypeRule?.itemList?.length > 0;
export const isMaximumRuleConfiguredForAllDaysSame = (params: PARAM_INTERFACE) => params?.row?.promiseConfig?.maxTime?.length === 1 && params?.row?.promiseConfig?.maxTime[0]?.days?.length === 7;
export const isMaximumPromiseForVisibilityRuleActive = (params: PARAM_INTERFACE) => params?.row?.promiseConfig?.maxTimeToDisplay && Number(params.row.promiseConfig.maxTimeToDisplay) > 0;
export const isPromiseDynamicOrAutomatedOrPREDICTIVE = (param: PARAM_INTERFACE) => param?.row?.type === PROMISE_TYPE.AUTOMATED || param?.row?.type === PROMISE_TYPE.DYNAMIC || param?.row?.type === PROMISE_TYPE.PREDICTIVE;

export const getPromiseTimeInHourAndMinutes = (minutes: number) => `${Math.floor(minutes / 60)} hour ${minutes % 60} mins`;

export const getMinimumPromiseTime = (params: PARAM_INTERFACE) => {
  let minutes: number = 0;
  if (!isUndefined(params?.row?.promiseConfig?.minTime)) {
    minutes = Math.floor(Number(params?.row?.promiseConfig?.minTime) / 60);
  } else if (!isUndefined(params?.row?.defaultOrFixedTime)) {
    minutes = Math.floor(Number(params?.row.defaultOrFixedTime) / 60);
  }
  return getPromiseTimeInHourAndMinutes(minutes);
};

export const getMaximumPromiseTime = (params: PARAM_INTERFACE) => {
  let minutes: number = 0;
  if (!isUndefined(params?.row?.promiseConfig?.maxTime?.length === 1 && params?.row?.promiseConfig?.maxTime[0]?.days?.length === 7 && params?.row?.promiseConfig?.maxTime[0]?.time)) {
    minutes = Math.floor(Number(params?.row?.promiseConfig?.maxTime[0]?.time) / 60);
  }
  return getPromiseTimeInHourAndMinutes(minutes);
};

export const getPromiseTypeName = (params: PARAM_INTERFACE) => {
  if (params?.row?.type) {
    return params?.row?.type;
  }
  return isDynamicPromiseFlag(params);
};

/**
 *
 * View Utils
 */

export const renderColumnCell = (classes: any, value: string | number) => <Typography className={classes.recordSpanStyle}>{value}</Typography>;
export const renderToolTipColumn = (classes: any, value: string | number) => <Typography className={classes.recordToolTipSpanStyle}>{value}</Typography>;
export const renderToolTipColumnCell = (classes: any) => <Typography className={classes.ScalableItemsSpanStyle}>{RULE_ENGINE_LISTING_TEXT_CONSTANT.ACTIVE}</Typography>;
export const renderPosName = (classes: any, posName: string | undefined | null) => <Tooltip title={posName || ""}>{renderColumnCell(classes, posName || "")}</Tooltip>;
export const renderColumnCellWithTootlTip = (classes: any, toolTipValue: NonNullable<React.ReactNode>) => <Tooltip title={toolTipValue}>{renderToolTipColumnCell(classes)}</Tooltip>;

export const renderToolTipCell = (classes: any, xsValue: any, value: string | number) => {
  return (
    <Grid item xs={xsValue}>
      {renderToolTipColumn(classes, value)}
    </Grid>
  );
};

export const renderEmptyColumn = (classes: any) => {
  return (
    <Grid item xs={1}>
      {renderToolTipColumn(classes, RULE_ENGINE_LISTING_TEXT_CONSTANT.EMPTY_COLUMN)}
    </Grid>
  );
};

export const renderStatus = (classes: any, params: PARAM_INTERFACE) => {
  return (
    <div style={{ alignSelf: "center" }}>
      <Typography className={isStatusActivated(params) ? classes.successSpanStyle : classes.failureSpanStyle}>
        {isStatusActivated(params) ? RULE_ENGINE_LISTING_TEXT_CONSTANT.ACTIVE : RULE_ENGINE_LISTING_TEXT_CONSTANT.IN_ACTIVE}
      </Typography>
    </div>
  );
};

export const renderDynamicPromise = (classes: any, params: PARAM_INTERFACE) => renderColumnCell(classes, capitalizeFirstLetter(getPromiseTypeName(params)));
export const renderMinimumPromiseTime = (classes: any, params: PARAM_INTERFACE) => renderColumnCell(classes, getMinimumPromiseTime(params));

export const renderMaximumPromiseTimeToolTipNode = (classes: any, params: PARAM_INTERFACE) => {
  if (params?.row?.promiseConfig?.maxTime?.length > 0) {
    return params?.row?.promiseConfig?.maxTime?.map((config: PromiseConfigMaxTime, configInde: number) => (
      <Grid container spacing={1} key={`${configInde}-promiseConfig-maxTime`} className={classes.toolTipContainerStyel}>
        <Grid container spacing={1}>
          {renderToolTipCell(classes, 2, HEADER_NAME_CONSTANT.DAYS)}
          {renderEmptyColumn(classes)}
          <Grid item xs={9} style={{ wordBreak: "break-all" }}>
            {config?.days?.length > 0 ? config?.days.join(", ") : RULE_ENGINE_LISTING_TEXT_CONSTANT.EMPTY_COLUMN}
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          {renderToolTipCell(classes, 7, HEADER_NAME_CONSTANT.MAXIMUM_PROMISE_TIME)}
          {renderEmptyColumn(classes)}
          {renderToolTipCell(classes, 4, config?.time ? getPromiseTimeInHourAndMinutes(Number(config.time)) : RULE_ENGINE_LISTING_TEXT_CONSTANT.EMPTY_COLUMN)}
        </Grid>
      </Grid>
    ));
  }
  return renderToolTipCell(classes, 7, RULE_ENGINE_LISTING_TEXT_CONSTANT.NO_DATA_AVAILABLE);
};

export const renderMaximumPromiseTime = (classes: any, params: PARAM_INTERFACE) => {
  if (isPromiseDynamicOrAutomatedOrPREDICTIVE(params)) {
    if (isMaximumPromiseRuleActive(params)) {
      if (isMaximumRuleConfiguredForAllDaysSame(params)) {
        return renderColumnCell(classes, getMaximumPromiseTime(params));
      }
      return renderColumnCellWithTootlTip(classes, renderMaximumPromiseTimeToolTipNode(classes, params));
    }
    return renderColumnCell(classes, RULE_ENGINE_LISTING_TEXT_CONSTANT.NO_DATA);
  }
  return renderColumnCell(classes, RULE_ENGINE_LISTING_TEXT_CONSTANT.N_A);
};

export const renderLastMileTime = (classes: any, params: PARAM_INTERFACE) => {
  let lastMileTime: string | number = RULE_ENGINE_LISTING_TEXT_CONSTANT.NO_DATA;
  if (isPromiseAutomated(params)) {
    if (!isUndefined(params?.row?.automatedConfig?.lastMileTime) && Number(params?.row?.automatedConfig?.lastMileTime) > 0) {
      lastMileTime = getPromiseTimeInHourAndMinutes(Math.floor(Number(params?.row?.automatedConfig?.lastMileTime) / 60));
    }
  } else {
    lastMileTime = RULE_ENGINE_LISTING_TEXT_CONSTANT.N_A;
  }
  return renderColumnCell(classes, lastMileTime);
};

export const convertVehicleTypeListToDesignView = (listData: any, classes: any) => {
  if (listData?.length > 0) {
    return (
      <>
        <Grid container spacing={0}>
          {renderToolTipCell(classes, 5, HEADER_NAME_CONSTANT.VEHICLE_TYPE)}
          {renderToolTipCell(classes, 5, HEADER_NAME_CONSTANT.THRESHOLD_WEIGHT)}
        </Grid>
        {listData.map((item: any, item_index: number) => (
          <Grid container spacing={0} key={`${item_index}-listData`}>
            {renderToolTipCell(classes, 5, item?.vehicleType)}
            {renderToolTipCell(classes, 7, convertWeightInGramsToKiloGrams(item?.thresholdWeight))}
          </Grid>
        ))}
      </>
    );
  }
  return RULE_ENGINE_LISTING_TEXT_CONSTANT.NO_DATA;
};

export const renderVehicleTypeToolTipNode = (classes: any, params: PARAM_INTERFACE) => {
  return (
    <Grid container spacing={1} className={classes.toolTipContainerStyel}>
      <Grid container spacing={0}>
        {renderToolTipCell(classes, 7, HEADER_NAME_CONSTANT.HEAVIEST_VEHICLE)}
        {renderEmptyColumn(classes)}
        {renderToolTipCell(classes, 4, params?.row?.rulesData?.vehicleTypeRule?.heaviestVehicle || RULE_ENGINE_LISTING_TEXT_CONSTANT.EMPTY_COLUMN)}
      </Grid>
      {params?.row?.rulesData?.vehicleTypeRule?.vehicleTypeWeightList?.length > 0 && (
        <Grid container spacing={0}>
          {renderToolTipCell(classes, 7, HEADER_NAME_CONSTANT.VEHICLE_TYPE_WEIGHT_LIST)}
          {renderEmptyColumn(classes)}
          {renderToolTipCell(classes, 5, "")}
          <Grid item xs={11}>
            {convertVehicleTypeListToDesignView(params.row.rulesData.vehicleTypeRule.vehicleTypeWeightList, classes)}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export const renderTypeOfVehicleRule = (classes: any, params: PARAM_INTERFACE) => {
  if (isPromiseStaticOrDynamic(params)) {
    if (isVehicleTypeRuleActive(params)) {
      return renderColumnCellWithTootlTip(classes, renderVehicleTypeToolTipNode(classes, params));
    }
    return renderColumnCell(classes, RULE_ENGINE_LISTING_TEXT_CONSTANT.NO_DATA);
  }
  return renderColumnCell(classes, RULE_ENGINE_LISTING_TEXT_CONSTANT.N_A);
};

export const renderItemCountRuleToolTipNode = (classes: any, params: PARAM_INTERFACE) => {
  const incrementalTime = params?.row?.rulesData?.countRule?.incrementalTime ? `${params?.row?.rulesData?.countRule?.incrementalTime} seconds` : RULE_ENGINE_LISTING_TEXT_CONSTANT.EMPTY_COLUMN;
  return (
    <Grid container spacing={1} className={classes.toolTipContainerStyel}>
      <Grid container spacing={2}>
        {renderToolTipCell(classes, 7, HEADER_NAME_CONSTANT.COUNT_THRESHOLD)}
        {renderEmptyColumn(classes)}
        {renderToolTipCell(classes, 4, params?.row?.rulesData?.countRule?.thresholdCount || RULE_ENGINE_LISTING_TEXT_CONSTANT.EMPTY_COLUMN)}
      </Grid>

      <Grid container spacing={2}>
        {renderToolTipCell(classes, 7, HEADER_NAME_CONSTANT.COUNT_INCREMENTAL_TIME)}
        {renderEmptyColumn(classes)}
        {renderToolTipCell(classes, 4, incrementalTime)}
      </Grid>
    </Grid>
  );
};
export const renderItemCountRule = (classes: any, params: PARAM_INTERFACE) => {
  if (isPromiseDynamic(params)) {
    if (isCountRuleActive(params)) {
      return renderColumnCellWithTootlTip(classes, renderItemCountRuleToolTipNode(classes, params));
    }
    return renderColumnCell(classes, RULE_ENGINE_LISTING_TEXT_CONSTANT.NO_DATA);
  }
  return renderColumnCell(classes, RULE_ENGINE_LISTING_TEXT_CONSTANT.N_A);
};

export const renderWeightRuleToolTipNode = (classes: any, params: PARAM_INTERFACE) => {
  const weightThreshold = params?.row?.rulesData?.weightRule?.thresholdWeight
    ? convertWeightInGramsToKiloGrams(params?.row?.rulesData?.weightRule?.thresholdWeight)
    : RULE_ENGINE_LISTING_TEXT_CONSTANT.EMPTY_COLUMN;
  const incrementalTime = params?.row?.rulesData?.weightRule?.incrementalTime
    ? `${convertSecondsIntoMinutes(params.row.rulesData.weightRule.incrementalTime)} mins`
    : RULE_ENGINE_LISTING_TEXT_CONSTANT.EMPTY_COLUMN;

  return (
    <Grid container spacing={1} className={classes.toolTipContainerStyel}>
      <Grid container spacing={2}>
        {renderToolTipCell(classes, 7, HEADER_NAME_CONSTANT.WEIGHT_THRESHOLD)}
        {renderEmptyColumn(classes)}
        {renderToolTipCell(classes, 4, weightThreshold)}
      </Grid>

      <Grid container spacing={2}>
        {renderToolTipCell(classes, 7, HEADER_NAME_CONSTANT.WEIGHT_INCREMENTAL_TIME)}
        {renderEmptyColumn(classes)}
        {renderToolTipCell(classes, 4, incrementalTime)}
      </Grid>
    </Grid>
  );
};

export const renderWeightRule = (classes: any, params: PARAM_INTERFACE) => {
  if (isPromiseDynamic(params)) {
    if (isWeightRuleActive(params)) {
      return renderColumnCellWithTootlTip(classes, renderWeightRuleToolTipNode(classes, params));
    }
    return renderColumnCell(classes, RULE_ENGINE_LISTING_TEXT_CONSTANT.NO_DATA);
  }
  return renderColumnCell(classes, RULE_ENGINE_LISTING_TEXT_CONSTANT.N_A);
};

export const renderCountIncrementalTimeToolTipNode = (classes: any, params: PARAM_INTERFACE) => {
  if (params?.row?.rulesData?.orderVelocityRule?.orderVelocityRuleValues?.length > 0) {
    return params.row.rulesData.orderVelocityRule.orderVelocityRuleValues.map((element: OrderVelocityRuleValue, element_index: number) => (
      <Grid container spacing={1} key={`${element_index}-orderVelocityRule-orderVelocityRuleValues`} className={classes.toolTipContainerStyel}>
        <Grid container spacing={1}>
          {renderToolTipCell(classes, 2, HEADER_NAME_CONSTANT.DAYS)}
          {renderEmptyColumn(classes)}
          <Grid item xs={9} style={{ wordBreak: "break-all" }}>
            <Typography className={classes.recordToolTipSpanStyle}>
              {element?.configuredDays?.length > 0 ? element.configuredDays.join(", ") : RULE_ENGINE_LISTING_TEXT_CONSTANT.EMPTY_COLUMN}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          {renderToolTipCell(classes, 7, HEADER_NAME_CONSTANT.TIME_RANGE)}
          {renderEmptyColumn(classes)}
          {renderToolTipCell(classes, 4, element?.timeRange ? `${convertSecondsIntoMinutes(element?.timeRange)} Minutes` : RULE_ENGINE_LISTING_TEXT_CONSTANT.EMPTY_COLUMN)}
        </Grid>

        <Grid container spacing={1}>
          {renderToolTipCell(classes, 7, HEADER_NAME_CONSTANT.ORDER_THRESHOLD)}
          {renderEmptyColumn(classes)}
          {renderToolTipCell(classes, 4, element?.orderThreshold || RULE_ENGINE_LISTING_TEXT_CONSTANT.EMPTY_COLUMN)}
        </Grid>

        <Grid container spacing={1}>
          {renderToolTipCell(classes, 7, HEADER_NAME_CONSTANT.EXTRA_PROMISE_TIME)}
          {renderEmptyColumn(classes)}
          {renderToolTipCell(classes, 4, element?.extraPromiseTime ? `${convertSecondsIntoMinutes(element?.extraPromiseTime)} Minutes` : RULE_ENGINE_LISTING_TEXT_CONSTANT.EMPTY_COLUMN)}
        </Grid>
      </Grid>
    ));
  }
  return renderToolTipCell(classes, 7, RULE_ENGINE_LISTING_TEXT_CONSTANT.NO_DATA_AVAILABLE);
};

export const renderCountIncrementalTime = (classes: any, params: PARAM_INTERFACE) => {
  if (isPromiseDynamic(params)) {
    if (isOrderVelocityRuleActive(params)) {
      return renderColumnCellWithTootlTip(classes, renderCountIncrementalTimeToolTipNode(classes, params));
    }
    return renderColumnCell(classes, RULE_ENGINE_LISTING_TEXT_CONSTANT.NO_DATA);
  }
  return renderColumnCell(classes, RULE_ENGINE_LISTING_TEXT_CONSTANT.N_A);
};

export const renderScalableItemsToolTipNode = (classes: any, params: PARAM_INTERFACE) => {
  if (params?.row?.rulesData?.scalableTypeRule?.itemList?.length > 0) {
    return (
      <Grid container className={classes.lineHeightStyle}>
        {params.row.rulesData.scalableTypeRule.itemList.map((item: ScalableItemType) => (
          <Grid item xs={12} key={item?.section}>
            <Typography className={classes.recordToolTipSpanStyle}>{`${item?.section} - ${convertSecondsIntoMinutes(item?.incrementalTime)} mins`}</Typography>
          </Grid>
        ))}
      </Grid>
    );
  }
  return renderToolTipCell(classes, 7, RULE_ENGINE_LISTING_TEXT_CONSTANT.NO_DATA_AVAILABLE);
};

export const renderScalableItems = (classes: any, params: PARAM_INTERFACE) => {
  if (isPromiseDynamic(params)) {
    if (isScalableTypeRuleActive(params)) {
      return renderColumnCellWithTootlTip(classes, renderScalableItemsToolTipNode(classes, params));
    }
    return renderColumnCell(classes, RULE_ENGINE_LISTING_TEXT_CONSTANT.NO_DATA);
  }
  return renderColumnCell(classes, RULE_ENGINE_LISTING_TEXT_CONSTANT.N_A);
};

export const renderActionColumn = (classes: any, params: PARAM_INTERFACE) => {
  const { countryId, posNo, serviceType, zoneId } = params?.row;
  const searchParam: string = `?countryId=${countryId}&serviceType=${serviceType}&posNo=${posNo}&zoneId=${zoneId}`;
  return (
    <Link to={{ pathname: `${APP_ROUTES.RULE_ENGINE_CURRENT_CONFIGURATION}`, search: searchParam }} className={classes.completeSetupSettingsButton}>
      <img src={settingsIcon} alt="settings" />
    </Link>
  );
};

export const getMaximumPromiseTimeForVisibility = (params: PARAM_INTERFACE) => {
  let minutes: number = 0;
  if (!isUndefined(params?.row?.promiseConfig?.maxTimeToDisplay)) {
    minutes = Math.floor(Number(params?.row?.promiseConfig?.maxTimeToDisplay) / 60);
  }
  return getPromiseTimeInHourAndMinutes(minutes);
};

export const renderMaximumPromiseTimeForVisibility = (classes: any, params: PARAM_INTERFACE) => {
  if (isPromiseDynamicOrAutomated(params)) {
    if(isMaximumPromiseForVisibilityRuleActive(params)){
      return renderColumnCell(classes, getMaximumPromiseTimeForVisibility(params));
    }
    return renderColumnCell(classes, RULE_ENGINE_LISTING_TEXT_CONSTANT.NO_DATA);
  }
  return renderColumnCell(classes, RULE_ENGINE_LISTING_TEXT_CONSTANT.N_A);
}

export const getCurrentConfigTableColumn = (classes: any, userInfo: any) => {
  const defaultTableWidthSet = Object.freeze({ sortable: false, headerAlign: "left", headerClassName: classes.headerStyle });
  const defaultTableSet = Object.freeze({ flex: 1, sortable: false, headerAlign: "left", headerClassName: classes.headerStyle });

  let coulums: any = [
    { ...defaultTableWidthSet, field: "posNo", headerName: HEADER_NAME_CONSTANT.POS_NO, width: 90, renderCell: (params: PARAM_INTERFACE) => renderColumnCell(classes, params?.row?.posNo) },
    { ...defaultTableSet, field: "posName", headerName: HEADER_NAME_CONSTANT.POS_NAME, renderCell: (params: PARAM_INTERFACE) => renderPosName(classes, params?.row?.posName) },
    { ...defaultTableSet, field: "zoneName", headerName: HEADER_NAME_CONSTANT.ZONE_NAME, renderCell: (params: PARAM_INTERFACE) => renderColumnCell(classes, params?.row?.zoneName) },
    { ...defaultTableSet, field: "status", headerName: HEADER_NAME_CONSTANT.STATUS, renderCell: (params: PARAM_INTERFACE) => renderStatus(classes, params) },
    { ...defaultTableSet, field: "promiseType", headerName: HEADER_NAME_CONSTANT.PROMISE_TYPE, renderCell: (params: PARAM_INTERFACE) => renderDynamicPromise(classes, params) },
    { ...defaultTableSet, field: "minimumPromiseTime", headerName: HEADER_NAME_CONSTANT.MINIMUM_PROMISE_TIME, renderCell: (params: PARAM_INTERFACE) => renderMinimumPromiseTime(classes, params) },
    // { ...defaultTableSet, field: "typeOfVehicleRule", headerName: HEADER_NAME_CONSTANT.TYPE_OF_VEHICLE, renderCell: (params: PARAM_INTERFACE) => renderTypeOfVehicleRule(classes, params) },
    { ...defaultTableSet, field: "itemCountRule", headerName: HEADER_NAME_CONSTANT.ITEM_COUNT, renderCell: (params: PARAM_INTERFACE) => renderItemCountRule(classes, params) },
    { ...defaultTableSet, field: "weightRuleOfConsignment", headerName: HEADER_NAME_CONSTANT.WEIGHT_OF_CONSIGNMENT, renderCell: (params: PARAM_INTERFACE) => renderWeightRule(classes, params) },
    { ...defaultTableSet, field: "countIncrementalTime", headerName: HEADER_NAME_CONSTANT.ORDER_VELOCITY, renderCell: (params: PARAM_INTERFACE) => renderCountIncrementalTime(classes, params) },
    { ...defaultTableSet, field: "scalableItems", headerName: HEADER_NAME_CONSTANT.INCREMENTAL_TIME_FOR_SCALABLE_ITEMS, renderCell: (params: PARAM_INTERFACE) => renderScalableItems(classes, params) },
    { ...defaultTableSet, field: "lastMileTime", headerName: HEADER_NAME_CONSTANT.LAST_MILE_TIME, renderCell: (params: PARAM_INTERFACE) => renderLastMileTime(classes, params) },
    { ...defaultTableSet, field: "maximumPromiseTime", headerName: HEADER_NAME_CONSTANT.MAXIMUM_PROMISE_TIME, renderCell: (params: PARAM_INTERFACE) => renderMaximumPromiseTime(classes, params) },
    { ...defaultTableSet, field: "maximumPromiseTimeForVisibility", headerName: HEADER_NAME_CONSTANT.MAXIMUM_PROMISE_TIME_FOR_VISIBILITY, renderCell: (params: PARAM_INTERFACE) => renderMaximumPromiseTimeForVisibility(classes, params) },
  ];

  if (userInfo && canChangeRuleEngineConfig(userInfo) === true) {
    coulums.push({ ...defaultTableWidthSet, field: "actions", headerName: HEADER_NAME_CONSTANT.ACTIONS, renderCell: (params: PARAM_INTERFACE) => renderActionColumn(classes, params) });
  }
  return coulums;
};
