import React from "react";

import moment from "moment";

import { Grid, Typography } from "@material-ui/core";
import { exclusionSlotMessage } from "../../../../utils/MessageUtils";
import { HH_MM_SS, SERVICE_TYPES } from "../../../../config/constants";
import { RenderDefaultServiceSlots } from "../DefaultServiceSlot";
import { RenderExpressOnDemandSlots } from "../ExpressServiceSlot";
import { RenderTemplateSlots } from "../ServiceSlotUtils";
import { getBufferCapacityConfigKeyNameFromZoneTab, getKeyNameFromZoneTab } from "../../../../utils/ServiceTypeUtils";
import { POS_ZONE_STATE_STATUS } from "../../../ServiceAndSlots/utils/constants";
import { isEmptyOrNull, isUndefined } from "../../../../utils/DataUtils";
import { convertUTCDate } from "../../../../utils/helperFunctions";

import RenderDynamicRoutingConfiguration from "./RenderDynamicRoutingConfiguration";

interface RenderServiceAllInformationInterface {
  classes: any;
  tab: any;
  cp?: any;
  serviceTypeName: any;
}

const RenderServiceAllInformation = (props: RenderServiceAllInformationInterface) => {
  const { classes, tab, cp, serviceTypeName } = props;
  const {
    foodTypeMapping: { foodTypes },
    dayWiseSlots,
    collectionPoints,
  } = tab;

  const convertDate = (busyEndTime: any, timezone?: any) => {
    if (busyEndTime) {
      if (timezone) {
        return moment(convertUTCDate(busyEndTime, timezone)).format("MMMM Do YYYY, h:mm a");
      }
      return moment(convertUTCDate(busyEndTime)).format("MMMM Do YYYY, h:mm a");
    }
    return "";
  };

  return (
    <>
      <Grid container className={classes.detailsSection}>
        <Grid item xs={12}>
          <Typography className={classes.fieldTitleStyle}>{`${exclusionSlotMessage}`}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.fieldData}>{foodTypes && foodTypes.length > 0 ? foodTypes?.join(", ") : "None"}</Typography>
        </Grid>
      </Grid>
      {serviceTypeName === SERVICE_TYPES.EXPRESS && (
        <Grid container className={classes.detailsSection}>
          <Grid item xs={12}>
            <Typography className={classes.fieldTitleStyle}>{`Accepting Orders Configuration`}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={getKeyNameFromZoneTab(tab, "state") === POS_ZONE_STATE_STATUS.AVAILABLE ? classes.availableZoneSpanStyle : classes.busyZoneSpanStyle}>
              {getKeyNameFromZoneTab(tab, "state") === POS_ZONE_STATE_STATUS.AVAILABLE ? `Accepting Orders` : `Not Accepting Orders`}
              {!isUndefined(getKeyNameFromZoneTab(tab, "busyEndTime")) && getKeyNameFromZoneTab(tab, "state") === POS_ZONE_STATE_STATUS.BUSY && (
                <Typography className={classes.textStyle}>
                  {`Zone Active Time `}
                  <Typography className={classes.textStyleBold}>{` : ${convertDate(getKeyNameFromZoneTab(tab, "busyEndTime"), tab?.timezone)}`}</Typography>
                </Typography>
              )}
            </Typography>
          </Grid>
        </Grid>
      )}
      <div className={classes.slotsInformation}>
        <Grid container>
          {(getKeyNameFromZoneTab(tab, "showFirstSlotAt") || getKeyNameFromZoneTab(tab, "serviceCutOffTime")) && (
            <Grid container className={classes.detailsSection}>
              {getKeyNameFromZoneTab(tab, "showFirstSlotAt") && (
                <Grid item xs={2}>
                  <Grid container className={classes.fieldData}>
                    <Grid item xs={12}>
                      <Typography className={classes.fieldTitle}>Show First Slot At</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.fieldData}>{moment(getKeyNameFromZoneTab(tab, "showFirstSlotAt"), HH_MM_SS).format("HH:mm")}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {getKeyNameFromZoneTab(tab, "serviceCutOffTime") && (
                <Grid item xs={2}>
                  <Grid container className={classes.fieldData}>
                    <Grid item xs={12}>
                      <Typography className={classes.fieldTitle}>Service Cut-Off Time</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.fieldData}>{moment(getKeyNameFromZoneTab(tab, "serviceCutOffTime"), HH_MM_SS).format("HH:mm")}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}
          {!isEmptyOrNull(getBufferCapacityConfigKeyNameFromZoneTab(tab, "id")) && (
            <Grid container className={classes.detailsSection}>
              <Grid item xs={12}>
                <Typography className={classes.fieldData}>{`Capacity Optimization`}</Typography>
                <Grid container>
                  <Grid item xs={2}>
                    <Grid container className={classes.fieldData}>
                      <Grid item xs={12}>
                        <Typography className={classes.fieldTitle}>Status</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography className={classes.fieldData}>{tab?.zone?.bufferCapacityConfig?.active || false ? "Active" : "Inactive"}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={3}>
                    <Grid container className={classes.fieldData}>
                      <Grid item xs={12}>
                        <Typography className={classes.fieldTitle}>Capacity Utilized (%)</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography className={classes.fieldData}>{getBufferCapacityConfigKeyNameFromZoneTab(tab, "capacityUtilized")}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={5}>
                    <Grid container className={classes.fieldData}>
                      <Grid item xs={12}>
                        <Typography className={classes.fieldTitle}>Buffer Time Decrease (minutes)</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography className={classes.fieldData}>{getBufferCapacityConfigKeyNameFromZoneTab(tab, "reducedBufferTime")}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={2}>
                    <Grid container className={classes.fieldData}>
                      <Grid item xs={12}>
                        <Typography className={classes.fieldTitle}>Max Orders </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography className={classes.fieldData}>{getBufferCapacityConfigKeyNameFromZoneTab(tab, "maxCapacity")}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        {serviceTypeName === SERVICE_TYPES.STANDARD && (
          <>
            <div className={classes.horizontalLine} />
            <RenderDynamicRoutingConfiguration tab={tab} />
          </>
        )}
        <div className={classes.horizontalLine} />
        <Grid container>
          <Grid item className={classes.slotsAllDaysText}>
            Slots (All Days)
          </Grid>
          <Grid item xs={12} className={classes.dayWiseSlotsContainer}>
            {serviceTypeName === SERVICE_TYPES.EXPRESS && <RenderExpressOnDemandSlots classes={classes} dayWiseSlots={dayWiseSlots} />}
            {serviceTypeName === SERVICE_TYPES.STANDARD && <RenderTemplateSlots classes={classes} dayWiseSlots={dayWiseSlots} />}
            {serviceTypeName === SERVICE_TYPES.DEFAULT && <RenderDefaultServiceSlots dayWiseSlots={dayWiseSlots} classes={classes} />}
            {serviceTypeName === SERVICE_TYPES.CLICK_N_COLLECT && <RenderTemplateSlots classes={classes} dayWiseSlots={collectionPoints[cp].dayWiseSlots} />}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default React.memo(RenderServiceAllInformation);
