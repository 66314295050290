import React from "react";

import { Checkbox, FormControlLabel, Grid, Typography } from "@material-ui/core";
import { PROMISE_TYPE } from "../../RuleEngineUtils";
import { capitalizeFirstLetter } from "../../../../utils/DataUtils";

interface RenderTypeOfPromiseCardInterface {
  classes: any;
  config: any;
  handlePromiseTypeCheckboxChange: (typeOfPromise: string) => void;
}

const RenderTypeOfPromiseCard = (props: RenderTypeOfPromiseCardInterface) => {
  const { classes, config, handlePromiseTypeCheckboxChange } = props;

  return (
    <Grid className={classes.typeOfPromiseContainer} container spacing={0}>
      <Grid item xs={2}>
        <Typography className={classes.typeOfPromiseText}>Type of Promise</Typography>
      </Grid>
      <Grid item xs={10} className={classes.dFlexEnd}>
        <Grid container spacing={2} direction="row" justifyContent="flex-end" alignItems="center">
          <FormControlLabel
            control={<Checkbox checked={config.type === PROMISE_TYPE.STATIC} onChange={() => handlePromiseTypeCheckboxChange(PROMISE_TYPE.STATIC)} />}
            label={<Typography className={classes.typeFormControlLabelStyle}>{`${capitalizeFirstLetter(PROMISE_TYPE.STATIC)}`}</Typography>}
          />
          <FormControlLabel
            control={<Checkbox checked={config.type === PROMISE_TYPE.DYNAMIC} onChange={() => handlePromiseTypeCheckboxChange(PROMISE_TYPE.DYNAMIC)} />}
            label={<Typography className={classes.typeFormControlLabelStyle}>{`${capitalizeFirstLetter(PROMISE_TYPE.DYNAMIC)}`}</Typography>}
          />
          <FormControlLabel
            control={<Checkbox checked={config.type === PROMISE_TYPE.AUTOMATED} onChange={() => handlePromiseTypeCheckboxChange(PROMISE_TYPE.AUTOMATED)} />}
            label={<Typography className={classes.typeFormControlLabelStyle}>{`${capitalizeFirstLetter(PROMISE_TYPE.AUTOMATED)}`}</Typography>}
          />
          {/* <FormControlLabel
            control={<Checkbox checked={config.type === PROMISE_TYPE.PREDICTIVE} onChange={() => handlePromiseTypeCheckboxChange(PROMISE_TYPE.PREDICTIVE)} />}
            label={<Typography className={classes.typeFormControlLabelStyle}>{`${capitalizeFirstLetter(PROMISE_TYPE.PREDICTIVE)}`}</Typography>}
          /> */}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(RenderTypeOfPromiseCard);
