import React from "react";

import { Button, Grid, SvgIcon, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";

import TableHeaderFilter from "../../common/TableHeaderFilter/TableHeaderFilter";

import { useStyles } from "../../common/TableHeaderFilter/TableHeaderFilterStyles";
import { ReactComponent as closeButton } from "../../assets/svg/closeButton.svg";

import { allowPolygonServiceEdit } from "../Utils/PlolygonServicesUtils";
import PolygonPOSZoneRecordView from "./PolygonPOSZoneRecordView";
import Loader from "../../common/Loader/Loader";

interface PlolygonServicesCardHeaderInterface {
  classes: any;
  rowsData: any;
  userInfo: any;
  handleFilterPopupOpen: any;
  handleFilterPopupClose: any;
  appFilterPopUpObj: any;
  headerRowCheckboxOnChange: any;
  handlePosZoneSearch: any;
  handlePosZoneSearchTextChange: any;
  currentCountry: any;
  handleResetPopUpOnClick: any;
  handleApplyPopUpOnClick: any;
}

const PlolygonServicesCardHeader = (props: PlolygonServicesCardHeaderInterface) => {
  const {
    classes,
    rowsData,
    userInfo,
    handleFilterPopupOpen,
    handleFilterPopupClose,
    appFilterPopUpObj,
    headerRowCheckboxOnChange,
    handlePosZoneSearch,
    handlePosZoneSearchTextChange,
    currentCountry,
    handleResetPopUpOnClick,
    handleApplyPopUpOnClick,
  } = props;

  const popUpClasses = useStyles();

  const getPopUpViewContent = (popUpHeaderText: any) => {
    return (
      <Grid className={popUpClasses.popoverFilter} item>
        <Grid container className={popUpClasses.headerContainer}>
          <Typography className={popUpClasses.headerContainerSpanStyle}>{`${popUpHeaderText || "Filter List"}`}</Typography>
          <SvgIcon key={"Close_icon"} className={popUpClasses.closeIcon} component={closeButton} onClick={() => handleFilterPopupClose(appFilterPopUpObj.serviceType)} />
        </Grid>
        <Grid container className={popUpClasses.mainContainer}>
          {appFilterPopUpObj && appFilterPopUpObj.loading ? (
            <div className={popUpClasses.loaderDiv}>
              <Loader />
            </div>
          ) : (
            appFilterPopUpObj.openPopUp && (
              <PolygonPOSZoneRecordView
                classes={popUpClasses}
                appFilterPopUpObj={appFilterPopUpObj}
                headerRowCheckboxOnChange={headerRowCheckboxOnChange}
                handlePosZoneSearch={handlePosZoneSearch}
                handlePosZoneSearchTextChange={handlePosZoneSearchTextChange}
                currentCountry={currentCountry}
              />
            )
          )}
        </Grid>
        <Grid container className={popUpClasses.buttonsContainer}>
          <Button className={`${popUpClasses.button} ${popUpClasses.resetButtonStyle}`} onClick={() => handleResetPopUpOnClick(appFilterPopUpObj.serviceType)}>
            {"Reset"}
          </Button>
          <Button className={`${popUpClasses.button} ${popUpClasses.applyButtonStyle}`} onClick={() => handleApplyPopUpOnClick(appFilterPopUpObj.serviceType)}>
            {"Apply"}
          </Button>
        </Grid>
      </Grid>
    );
  };

  const getTableFilterView = (serviceType: any) => {
    return (
      <TableHeaderFilter
        openPopUp={appFilterPopUpObj.openPopUp}
        anchorEl={appFilterPopUpObj.anchorEl}
        //
        handleFilterPopupOpen={(event: any) => handleFilterPopupOpen(event, serviceType)}
        handleFilterPopupClose={handleFilterPopupClose}
        //
        popUpContent={getPopUpViewContent("POS - Zone List")}
        anchorName={serviceType}
      />
    );
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="none" size="small" align="left" className={`${classes.cellHeaderStyle} ${classes.cellPolygonIdStyle}`}>
          Polygon ID
        </TableCell>
        <TableCell padding="none" size="small" align="left" className={`${classes.cellHeaderStyle} ${classes.cellPolygonServiceStyle}`}>
          {"Standard"}
          {appFilterPopUpObj !== undefined && appFilterPopUpObj.standardFilter && Array.isArray(appFilterPopUpObj.standardFilter) && appFilterPopUpObj.standardFilter.length > 0 && (
            <span className={classes.filteredListSpanstyle}>{`${appFilterPopUpObj.standardFilter.length}`}</span>
          )}
          {getTableFilterView("Standard")}
        </TableCell>
        <TableCell padding="none" size="small" align="left" className={`${classes.cellHeaderStyle} ${classes.cellPolygonServiceStyle}`}>
          {"Express"}
          {appFilterPopUpObj !== undefined && appFilterPopUpObj.expressFilter && Array.isArray(appFilterPopUpObj.expressFilter) && appFilterPopUpObj.expressFilter.length > 0 && (
            <span className={classes.filteredListSpanstyle}>{`${appFilterPopUpObj.expressFilter.length}`}</span>
          )}
          {getTableFilterView("Express")}
        </TableCell>
        <TableCell padding="none" size="small" align="left" className={`${classes.cellHeaderStyle} ${classes.cellPolygonServiceStyle}`}>
          {"Default"}
          {appFilterPopUpObj !== undefined && appFilterPopUpObj.defaultFilter && Array.isArray(appFilterPopUpObj.defaultFilter) && appFilterPopUpObj.defaultFilter.length > 0 && (
            <span className={classes.filteredListSpanstyle}>{`${appFilterPopUpObj.defaultFilter.length}`}</span>
          )}
          {getTableFilterView("Default")}
        </TableCell>
        <TableCell padding="none" size="small" align="left" className={`${classes.cellHeaderStyle} ${classes.cellPolygonLastUpdatedStyle}`}>
          Last Updated
        </TableCell>
        {userInfo !== undefined &&
          userInfo.userProfile !== undefined &&
          userInfo.userProfile.role !== undefined &&
          userInfo.userProfile.role !== "" &&
          allowPolygonServiceEdit(userInfo.userProfile.role) && (
            <TableCell padding="none" size="small" align="left" className={`${classes.cellHeaderStyle} ${classes.cellPolygonActionStyle}`}>
              Action
            </TableCell>
          )}
      </TableRow>
    </TableHead>
  );
};

export default React.memo(PlolygonServicesCardHeader);
