import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import { Grid, SvgIcon, TableBody, TableCell, TableRow, Typography } from "@material-ui/core";

import Pencil from "../../assets/svg/Pencil.svg";
import { ReactComponent as ForwardIconRight } from "../../assets/svg/ForwardIconRight.svg";

import { POLYGON_CONSTANT_TEXT, TABLE_BODY_NO_KML_FILE_ISSUE, TABLE_BODY_UPLOAD_KML_FILE_ISSUE } from "../Utils/PlolygonServicesConstants";
import { allowPolygonServiceEdit } from "../Utils/PlolygonServicesUtils";
import { GetNoDataPresentAfterFiltereApplied, GetNoDataPresentAfterSearchApplied, GetNoDataPresentErrorViewUtils } from "../../common/App/AppViewUtils";
import { APP_ROUTES } from "../../../config/constants";
import { isArrayValid, isUndefined } from "utils/DataUtils";

interface PlolygonServicesCardBodyInterface {
  classes: any;
  rowsData: any;
  handle_make_row_editable: any;
  currentCountry: any;
  userInfo: any;
  appCountStateObj: any;
  appFilterPopUpObj: any;
  //
  hanlePolygonDetailsDrawerOpen: any;
  appStateObj: any;
}

const PlolygonServicesCardBody = (props: PlolygonServicesCardBodyInterface) => {
  const { classes, rowsData, handle_make_row_editable, currentCountry, userInfo, appCountStateObj, appFilterPopUpObj, hanlePolygonDetailsDrawerOpen, appStateObj } = props;

  return (
    <TableBody>
      {rowsData && Array.isArray(rowsData) && rowsData.length > 0 ? (
        rowsData.map((record: any, recordIndex: any) => (
          <TableRow key={`${recordIndex}-record-rowsData-PlolygonServicesCardBody`}>
            <TableCell align="left" className={`${classes.cellDataStyle} ${classes.cellPolygonIdStyle}`}>{`${record?.polygonId || ""}`}</TableCell>
            {Object.keys(record.serviceTypeMappings).map((serviceType: any, serviceTypeIndex: any) => (
              <TableCell align="left" className={`${classes.cellDataStyle} ${classes.cellPolygonServiceStyle}`} key={`${serviceTypeIndex}-serviceTypeIndex-${recordIndex}`}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    {!isUndefined(serviceType) &&
                    !isUndefined(record.serviceTypeMappings) &&
                    !isUndefined(record.serviceTypeMappings[serviceType]) &&
                    !isUndefined(record.serviceTypeMappings[serviceType].mappings) &&
                    isArrayValid(record.serviceTypeMappings[serviceType].mappings) ? (
                      record.serviceTypeMappings[serviceType].mappings.map((mappingObj: any, mappingObjIndex: number) => (
                        <div key={`${mappingObjIndex}-mappingObjIndex-${serviceType}-serviceTypeMappings`}>
                          {!isUndefined(mappingObj) && !isUndefined(mappingObj.isServiceable) && mappingObj.isServiceable ? (
                            <Typography className={classes.polygonServiceDetailsSpanStyle}>{`${
                              moment(mappingObj.startTime, POLYGON_CONSTANT_TEXT.BACK_END_TIME_FORMAT).isValid() ? moment(mappingObj.startTime, POLYGON_CONSTANT_TEXT.BACK_END_TIME_FORMAT).format(POLYGON_CONSTANT_TEXT.VIEW_TIME_FORMAT) : "N/A"
                            } : ${moment(mappingObj.endTime, POLYGON_CONSTANT_TEXT.BACK_END_TIME_FORMAT).isValid() ? moment(mappingObj.endTime, POLYGON_CONSTANT_TEXT.BACK_END_TIME_FORMAT).format(POLYGON_CONSTANT_TEXT.VIEW_TIME_FORMAT) : "N/A"} | ${
                              mappingObj?.address || ""
                            }`}</Typography>
                          ) : (
                            <Typography className={classes.polygonNonServiceableSpanStyle}>{`${
                              moment(mappingObj.startTime, POLYGON_CONSTANT_TEXT.BACK_END_TIME_FORMAT).isValid() ? moment(mappingObj.startTime, POLYGON_CONSTANT_TEXT.BACK_END_TIME_FORMAT).format(POLYGON_CONSTANT_TEXT.VIEW_TIME_FORMAT) : "N/A"
                            } : ${
                              moment(mappingObj.endTime, POLYGON_CONSTANT_TEXT.BACK_END_TIME_FORMAT).isValid() ? moment(mappingObj.endTime, POLYGON_CONSTANT_TEXT.BACK_END_TIME_FORMAT).format(POLYGON_CONSTANT_TEXT.VIEW_TIME_FORMAT) : "N/A"
                            } | Non Serviceable Area`}</Typography>
                          )}
                        </div>
                      ))
                    ) : (
                      <Typography className={classes.polygonServiceDetailsSpanStyle}>{`-`}</Typography>
                    )}
                  </Grid>
                </Grid>
              </TableCell>
            ))}
            <TableCell align="left" className={`${classes.cellDataStyle} ${classes.cellPolygonLastUpdatedStyle}`}>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  {`${record?.lastModifiedAt || "-"}`}
                  <SvgIcon key={"Forward_Icon_Right"} className={classes.forwardIconStyle} component={ForwardIconRight} onClick={() => hanlePolygonDetailsDrawerOpen(record?.polygonId, record?.polygonName)} />
                </Grid>
              </Grid>
            </TableCell>
            {userInfo !== undefined && userInfo.userProfile !== undefined && userInfo.userProfile.role !== undefined && userInfo.userProfile.role !== "" && allowPolygonServiceEdit(userInfo.userProfile.role) && (
              <TableCell align="left" className={`${`${classes.cellDataStyle}`} ${classes.cellPolygonActionStyle}`}>
                <Link to={{ pathname: `${APP_ROUTES.POLYGON_SERVICES_CONFIGURATION}`, search: `?${POLYGON_CONSTANT_TEXT.POLYGON_ID_PARAM}=${record?.polygonId}` }} style={{ margin: "auto" }}>
                  <img src={Pencil} alt="Edit" className={classes.actionIconStyle} />
                </Link>
              </TableCell>
            )}
          </TableRow>
        ))
      ) : (
        <TableRow>
          <TableCell rowSpan={1} colSpan={6} className={classes.noDataRowStyle}>
            {appFilterPopUpObj !== undefined &&
            ((appFilterPopUpObj.standardFilter !== undefined && Array.isArray(appFilterPopUpObj.standardFilter) && appFilterPopUpObj.standardFilter.length > 0) ||
              (appFilterPopUpObj.expressFilter !== undefined && Array.isArray(appFilterPopUpObj.expressFilter) && appFilterPopUpObj.expressFilter.length > 0) ||
              (appFilterPopUpObj.defaultFilter !== undefined && Array.isArray(appFilterPopUpObj.defaultFilter) && appFilterPopUpObj.defaultFilter.length > 0)) ? (
              <GetNoDataPresentAfterFiltereApplied classes={classes} />
            ) : appStateObj !== undefined && appStateObj.searchValue !== undefined && appStateObj.searchValue !== "" ? (
              <GetNoDataPresentAfterSearchApplied classes={classes} />
            ) : appCountStateObj !== undefined && appCountStateObj.selectedTileFilter !== undefined && Number(appCountStateObj.selectedTileFilter) !== 0 ? (
              <GetNoDataPresentAfterFiltereApplied classes={classes} />
            ) : (
              <GetNoDataPresentErrorViewUtils classes={classes} currentCountry={currentCountry} ErrorMessage={TABLE_BODY_NO_KML_FILE_ISSUE} SecondaryErrorMessage={TABLE_BODY_UPLOAD_KML_FILE_ISSUE} />
            )}
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );
};

export default React.memo(PlolygonServicesCardBody);
