import React from "react";

import { Grid, Typography } from "@material-ui/core";

import SearchHeaderCustom from "../../common/SearchHeaderCustom/SearchHeaderCustom";
import TextBoxWithLabel from "../../common/TextBoxWithLabel/TextBoxWithLabel";
import TimePicker from "../../common/TimePicker/TimePicker";

import * as PlolygonServicesConstants from "../Utils/PlolygonServicesConstants";

import { isEmptyOrNull, isUndefined } from "../../../utils/DataUtils";
import { PlolygonServicesInitialStateInteface } from "../Utils/PlolygonServicesInterface";

interface PolygonServiceFilterSectionInterface {
  classes: any;
  appStateObj: PlolygonServicesInitialStateInteface;
  handleSearchTypeChange: any;
  handleSearchBoxTextChange: any;
  handleSearchBoxKeyDownChange: any;
  handleSearchIconClick: any;
  clearAllFilter: any;
  clearPosLevelFilter: () => void;
  moveMapToCordinates: () => void;
  handleServiceTimeChange: (name: string, dateData: any) => void;
}

const PolygonServiceFilterSection = (props: PolygonServiceFilterSectionInterface) => {
  const {
    classes,
    appStateObj,
    handleSearchTypeChange,
    handleSearchBoxTextChange,
    handleSearchBoxKeyDownChange,
    handleSearchIconClick,
    clearAllFilter,
    clearPosLevelFilter,
    moveMapToCordinates,
    handleServiceTimeChange,
  } = props;

  const getPlaceHolderText = () => {
    let placeholderText = "Search";
    let searchTypes = PlolygonServicesConstants.POLYGON_SERVICE_SEARCH_TYPES;
    if (appStateObj?.polygonView === PlolygonServicesConstants.POLYGON_VIEW_TYPE.MAP) {
      searchTypes = PlolygonServicesConstants.POLYGON_SERVICE_MAP_VIEW_SEARCH_TYPES;
    }
    if (!isUndefined(appStateObj?.searchTypeValue) && !isEmptyOrNull(appStateObj?.searchTypeValue)) {
      placeholderText = `Search by ${searchTypes.filter((x: any) => x.value === appStateObj.searchTypeValue)[0].name || appStateObj.searchTypeValue}`;
    }
    return placeholderText;
  };

  return (
    <Grid container className={classes.filterHeaderRoot}>
      <Grid item xs={8} className={classes.colorSchemeGridItemStyle}>
        {appStateObj?.polygonView === PlolygonServicesConstants.POLYGON_VIEW_TYPE.MAP && (
          <div className={classes.timePickerDiv}>
            <TimePicker
              value={appStateObj.currentTime}
              handleChange={(date: any) => handleServiceTimeChange("currentTime", date)}
              fieldLabel={undefined}
              className={undefined}
              timerProps={undefined}
              ampm={false}
            />
          </div>
        )}
        <SearchHeaderCustom
          searchTypes={
            appStateObj?.polygonView === PlolygonServicesConstants.POLYGON_VIEW_TYPE.MAP
              ? PlolygonServicesConstants.POLYGON_SERVICE_MAP_VIEW_SEARCH_TYPES
              : PlolygonServicesConstants.POLYGON_SERVICE_SEARCH_TYPES
          }
          searchType={appStateObj.searchTypeValue}
          handleSearchTypeChange={handleSearchTypeChange}
          searchValue={appStateObj.searchValue}
          handleSearchBoxTextChange={handleSearchBoxTextChange}
          handleSearchBoxKeyDownChange={handleSearchBoxKeyDownChange}
          placeholderText={getPlaceHolderText()}
          handleIconClick={handleSearchIconClick}
          resetRecord={clearAllFilter}
          showInputBox={appStateObj.searchTypeValue === PlolygonServicesConstants.POLYGON_CONSTANT_TEXT.LATLNG_VALUE ? true : undefined}
        />
        {appStateObj.searchTypeValue === PlolygonServicesConstants.POLYGON_CONSTANT_TEXT.LATLNG_VALUE && (
          <Grid item className={classes.colorSchemeGridItemStyle}>
            <TextBoxWithLabel
              label={"Latitude"}
              name="latSearchValue"
              value={appStateObj.latSearchValue}
              placeholderText="Latitude"
              handleChange={handleSearchBoxTextChange}
              className={classes.rightPaddingStyle}
              disabled={undefined}
              type="number"
            />
            <TextBoxWithLabel
              label={"Longitude"}
              name="lngSearchValue"
              value={appStateObj.lngSearchValue}
              placeholderText="Longitude"
              handleChange={handleSearchBoxTextChange}
              className={classes.rightPaddingStyle}
              disabled={undefined}
              type="number"
            />
            <Typography className={classes.resetSpanStyle} onClick={moveMapToCordinates}>{`Go`}</Typography>
          </Grid>
        )}
      </Grid>
      <Grid item xs={4} className={classes.clearFilterDiv}>
        {appStateObj && appStateObj.polygonView && appStateObj.polygonView === PlolygonServicesConstants.POLYGON_VIEW_TYPE.MAP && appStateObj.isPosLevelFilteredApplied && (
          <Typography className={classes.clearPOSFilterSpanStyle} onClick={() => clearPosLevelFilter()}>{`Clear POS Filters`}</Typography>
        )}
        <Typography className={classes.clearFilterSpanStyle} onClick={() => clearAllFilter()}>{`Clear All Filters`}</Typography>
      </Grid>
    </Grid>
  );
};

export default React.memo(PolygonServiceFilterSection);
