import moment from "moment";

import { CoordinatesInterface } from "../../../config/constants";
import { ALL_SERVICE_TYPE, IS_POLYGON_COUNT_IN_HEADER_VISIBLE, POLYGON_VIEW_TYPE, SERVICE_TYPE_RECORD_LIST, polygon_count_obj } from "./PlolygonServicesConstants";

export interface PlolygonServicesInitialCountStateInteface {
  loading: boolean;
  // Header Count
  countHeader: Array<any>;
  serviceTypeList: Array<any>;
  selectedServiceTypeFilter: Array<any>;
  selectedTileFilter: any;
  serviceTypeFilter: string;
}

export const PlolygonServicesInitialCountState: PlolygonServicesInitialCountStateInteface = {
  loading: false,
  // Header Count
  countHeader: [...polygon_count_obj],
  serviceTypeList: [...SERVICE_TYPE_RECORD_LIST],
  selectedServiceTypeFilter: [...ALL_SERVICE_TYPE],
  selectedTileFilter: 0,
  serviceTypeFilter: "",
};

export interface MapPolygonOptionInterface {
  fillColor: string;
  fillOpacity: number;
  strokeColor: string;
  strokeOpacity: number;
  strokeWeight: number;
  zIndex: number;
  fillColorCopy: string;
  strokeColorCopy: string;
}
export interface MapPolygonInterface {
  path: Array<CoordinatesInterface> | [];
  options: MapPolygonOptionInterface;
  open: boolean;
  polygonId: string;
  polygonKey: string;
  polygonName: string;
  serviceTypeMapping: any;
  posNo: Array<string>;
}

export interface PlolygonServicesInitialStateInteface {
  loading: boolean;
  overlayLoader: boolean;
  isCountHeaderVisible: boolean;
  isExporting: boolean;
  // Listing screen
  polygonServiceListData: any;
  page: any;
  size: any;
  maxRecordPresent: any;
  // Action
  editableRowIndex: any;
  // POS Zone
  posZoneSearchText: any;
  posZoneServiceRecord: any;
  posZoneServiceFilteredRecord: any;
  totalRowsCountData: any;
  // POS Zone Pop-up
  serviceTypeName: any;
  showPosZoneMappingPopUp: boolean;
  isNonServiceable: boolean;
  selectedPosZoneIndex: any;
  selectedPosZoneObj: any;
  // Search
  searchTypeValue: any;
  searchValue: any;
  // View
  polygonView: string;
  mapZoom: number;
  mapDefaultCenter: CoordinatesInterface;
  mapCenter: CoordinatesInterface;
  mapMarkersRecord: any;
  mapPolygonsRecord: Array<MapPolygonInterface> | [];
  // Info-Window
  openMapInfoWindow: boolean;
  mapInfoWindowRecord: any;
  event: any;
  isPosLevelFilteredApplied: boolean;
  posLevelFilterData: MAP_MARKER_INTERFACE | null;
  latSearchValue: number | string;
  lngSearchValue: number | string;
  // Time based filter
  currentTime: any;
  boundaries: any;
}

export const PlolygonServicesInitialState: PlolygonServicesInitialStateInteface = {
  loading: false,
  overlayLoader: false,
  isCountHeaderVisible: IS_POLYGON_COUNT_IN_HEADER_VISIBLE,
  isExporting: false,
  // Listing screen
  polygonServiceListData: [],
  page: 1,
  size: 50,
  maxRecordPresent: 0,
  // Action
  editableRowIndex: "",
  // POS Zone
  posZoneSearchText: "",
  posZoneServiceRecord: [],
  posZoneServiceFilteredRecord: [],
  totalRowsCountData: 0,
  // POS Zone Pop-up
  serviceTypeName: "",
  showPosZoneMappingPopUp: false,
  isNonServiceable: false,
  selectedPosZoneIndex: "",
  selectedPosZoneObj: {},
  // Search
  searchTypeValue: "polygonId",
  searchValue: "",
  // View
  polygonView: POLYGON_VIEW_TYPE.LISTING,
  mapZoom: 12,
  mapDefaultCenter: { lat: 0, lng: 0 },
  mapCenter: { lat: 0, lng: 0 },
  mapMarkersRecord: [],
  mapPolygonsRecord: [],
  // Info-Window
  openMapInfoWindow: false,
  mapInfoWindowRecord: null,
  event: null,
  isPosLevelFilteredApplied: false,
  posLevelFilterData: null,
  latSearchValue: "",
  lngSearchValue: "",
  // Time based filter
  currentTime: new Date().setHours(new Date().getHours()), //moment(new Date()).format("HH:mm:ss"),
  boundaries: null,
};

export const commonPopUpFilterObj: any = {
  openPopUp: false,
  anchorEl: null,
};

export interface PolygonFilterPopUpStateInterface {
  loading: boolean;
  serviceType: any;
  //
  openPopUp: any;
  anchorEl: any;
  //
  posZoneSearchText: any;
  posZoneServiceRecord: any;
  posZoneServiceFilteredRecord: any;
  //
  standardFilter: any;
  expressFilter: any;
  defaultFilter: any;
}

export const PolygonFilterPopUpInitialState: PolygonFilterPopUpStateInterface = {
  loading: false,
  serviceType: "",
  //
  openPopUp: false,
  anchorEl: null,
  //
  posZoneSearchText: "",
  posZoneServiceRecord: [],
  posZoneServiceFilteredRecord: [],
  //
  standardFilter: [],
  expressFilter: [],
  defaultFilter: [],
};

export interface PolygonDetailsSideDrawerInterface {
  openDrawer: boolean;
  loading: boolean;
  serviceType: any;
  polygonId: any;
  polygonName: any;
  polygonDetailsHistory: any;
}

export const PolygonDetailsSideDrawerInitialState: PolygonDetailsSideDrawerInterface = {
  openDrawer: false,
  loading: false,
  serviceType: "STANDARD",
  polygonId: "",
  polygonName: "",
  polygonDetailsHistory: [],
};

export interface MAPOPTIONSTYLES_INTERFACE {
  elementType: string;
  featureType?: string;
  stylers: { visibility: string }[];
}
export const mapOptionStyles: MAPOPTIONSTYLES_INTERFACE[] = [
  { elementType: "labels.icon", stylers: [{ visibility: "off" }] },
  { featureType: "administrative.land_parcel", elementType: "labels", stylers: [{ visibility: "off" }] },
  { featureType: "poi", elementType: "labels.text", stylers: [{ visibility: "off" }] },
  { featureType: "road.local", elementType: "labels", stylers: [{ visibility: "off" }] },
];

export interface MAP_MARKER_ICON_INTERFACE {
  url: string;
  scale: number;
  labelOrigin: {
    x: number;
    y: number;
  };
}

export interface MAP_MARKER_LABEL_INTERFACE {
  text: string | number;
  fontSize: string;
  fontWeight: string;
  color: string;
  lineHeight: string;
}
export interface MAP_MARKER_INTERFACE {
  key: string;
  name: string;
  location: CoordinatesInterface;
  icon?: MAP_MARKER_ICON_INTERFACE;
  label?: MAP_MARKER_LABEL_INTERFACE;
  address: any;
  serviceTypes: any;
  selectedServiceDetails: any;
  openWindow: boolean;
  posNo: string | number;
  posName: string;
  visible: boolean;
}

export interface POLYGON_MAP_SERVICE_TYPE_MAPPING_INTERFACE {
  id: number | string;
  posNo: string | number;
  posName: string;
  zoneId: string;
  zoneName: string;
  status: string;
  serviceTypeId: number;
  isServiceable: boolean;
  lastModifiedBy: string | null;
  lastModifiedAt: string | null;
}

export interface AppStateObjInterface {
  loading: boolean;
  isEditMode: boolean;
  showPosZoneMappingPopUp: boolean;
  isNonServiceable: boolean;
  posZoneSearchText: string;
  posZoneServiceRecord: any[]; // Replace with actual type of posZoneServiceRecord
  posZoneServiceFilteredRecord: any[]; // Replace with actual type of posZoneServiceFilteredRecord
  serviceTypeName: string;
  serviceRowIndex: string | number;
  mappingListRowIndex: string | number;
  selectedPosZoneIndex: string;
  selectedPosZoneObj: Record<string, any>; // Replace with actual type of selectedPosZoneObj
  serviceTypeRecordList: ServiceTypeRecord[];
  // Mapping
  polygonIdsSearchTextValue: string;
  allPolygonListIdsRecord: string[];
  mappedPolygonIdsSearchTextValue: string;
  mappedPolygonIdsRecord: string[];
  // Polygon Details Pop Up
  openPolygonDetailsPopUp: boolean;
  polygonDetailsPopUpData: any;
  // Update Polygon Id
  polygonId: string | null;
}

export interface ServiceTypeRecord {
  name: string;
  openAccordian: boolean;
  active: boolean;
  sameAsEarlier: boolean;
  enableConfigEdit: boolean;
  mappingList: MappingListItem[];
}

export interface MappingListItem {
  startTime: number | string | any;
  endTime: number | string | any;
  isNonServiceable: null | boolean;
  posId?: number;
  posNo?: string;
  posName?: string;
  zoneName?: string;
  zoneId?: number;
  serviceTypeId?: number;
  serviceType?: string;
  isChecked?: boolean;
  status?: string;
}

export const AppStateObj: AppStateObjInterface = {
  loading: false,
  isEditMode: false,
  //
  showPosZoneMappingPopUp: false,
  isNonServiceable: false,
  posZoneSearchText: "",
  posZoneServiceRecord: [],
  posZoneServiceFilteredRecord: [],
  serviceTypeName: "",
  serviceRowIndex: "",
  mappingListRowIndex: "",
  selectedPosZoneIndex: "",
  selectedPosZoneObj: {},
  //
  serviceTypeRecordList: [
    { name: "Standard", openAccordian: false, active: false, sameAsEarlier: false, enableConfigEdit: false, mappingList: [] },
    { name: "Express", openAccordian: false, active: false, sameAsEarlier: false, enableConfigEdit: false, mappingList: [] },
    { name: "Default", openAccordian: false, active: false, sameAsEarlier: false, enableConfigEdit: false, mappingList: [] },
  ],
  // Mapping
  polygonIdsSearchTextValue: "",
  allPolygonListIdsRecord: [],
  mappedPolygonIdsSearchTextValue: "",
  mappedPolygonIdsRecord: [],
  // Polygon Details Pop Up
  openPolygonDetailsPopUp: false,
  polygonDetailsPopUpData: {},
  // Update Polygon Id
  polygonId: null,
};

export interface ServiceTypeMapping {
  serviceType: string;
  serviceTypeId?: number;
  isServiceable: boolean;
  status?: string;
  posId?: string | number;
  posNo?: string;
  posName?: string;
  zoneName?: string;
  zoneId?: number;
  startTime: any;
  endTime: any;
}

export interface StoreDayWiseMapping {
  mappings: ServiceTypeMapping[];
}

export interface ServiceTypeMappings {
  serviceType: string;
  storeDayWiseMappings: StoreDayWiseMapping[];
}
export interface PayloadObjectInterface {
  polygonIds: string[];
  changedServiceTypes: string[];
  serviceTypeMappings: ServiceTypeMappings[];
}
